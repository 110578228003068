import React from "react";
import axios from 'axios';
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Notiflix from 'notiflix';
import "notiflix/dist/notiflix-3.2.6.min.css";
import {browserName, fullBrowserVersion, engineName, engineVersion, deviceType, osName, osVersion} from 'react-device-detect';

class ProxyLogin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: null,
            bo_id: null,
            total_share: null,
            proxy_name: null,
            proxy_boid: null,
            email: null,
            otp_for: 'mobile',
            otp_email: null,
            nid_number: null,
            dob: new Date(),
            password: '',
            protection: false,
            proxyusername: null,
            proxypassword: null,
            proxy_tp:0
        }
		this.key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
    }

	handleChange = (event) => {
		const value = event.target.value;
		const name = event.target.name;

        if(localStorage.bank_asia === '1'){

            if(name === 'proxy_boid' || name === 'bo_id'){

                this.setState({

                    [name]: value

                }, () => {

                    this.getName();

                });

            }else{

                this.setState({
                    [name]: value
                });

            }

        }else{

            if(localStorage.proxy_boid_login === '1'){

                if(name === 'proxy_boid'){

                    this.setState({
    
                        [name]: value
    
                    }, () => {
    
                        this.getName();
    
                    });
    
                }else{
    
                    this.setState({
                        [name]: value
                    });
    
                }
                
            }else{
                if(name === 'bo_id'){

                    this.setState({
    
                        [name]: value
    
                    }, () => {
    
                        this.getName();
    
                    });
    
                }else{
    
                    this.setState({
                        [name]: value
                    });
    
                }
            }



        }

	}

    handleChange2 = (value) => {
        this.setState({
            dob: value
        });
    }

    radioHandler = (value) => {
        this.setState({
            proxy_tp: value
        });
    }

    getName = (e) => {

        if(localStorage.bank_asia === '1'){

            const key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
            const url = `${localStorage.apiHost}/get-name-ba`;

            axios({
                method: 'post',
                url: url,
                data: {
                    'type': 3,
                    'type-status': 1,
                    'boid': this.state.bo_id,
                    'dob': this.state.dob,
                    'total-share': this.state.total_share,
                    'proxy-boid': this.state.proxy_boid,
                    'program-id': localStorage.programId,
                },
                headers: {
                    'key': key
                }

            }).then(response => {

                console.log(response.data);

                if (response.data.status === 200) {

                    document.getElementById("PName").value = response.data.name;
                    document.getElementById("total_share_p").value = response.data.total_share;

                    // document.getElementById("total_share_p").focus();

                    this.setState({
                        name: response.data.name,
                        total_share: response.data.total_share
                    });

                } else {

                    document.getElementById("PName").value = '';
                    document.getElementById("total_share_p").value = '';

                }
            });

        }else{


            const key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj';
            const url = `${localStorage.apiHost}/get-name`;

            axios({
                method: 'post',
                url: url,
                data: {
                    'boid': this.state.bo_id,
                    'proxy_boid': this.state.proxy_boid,
                    'program-id': localStorage.programId,
                },
                headers: {
                    'key': key
                }

            }).then(response => {

                console.log(response.data);

                if (response.data.status === 200) {

                    if(response.data.type === 3){

                        if(response.data.protected === 1){
                            this.setState({
                                protection: true
                            });
                        }

                        if(document.getElementById("PName")){
                            document.getElementById("PName").value = response.data.name;
                            this.setState({
                                name: response.data.name
                            });
                        }

                        if(document.getElementById("ProxyName")){
                            if(response.data.proxy_name != ''){
                                document.getElementById("ProxyName").value = response.data.proxy_name;
                                this.setState({
                                    proxy_name: response.data.proxy_name
                                });
                            }
                        }

                        if(document.getElementById("total_share_p")){
                            document.getElementById("total_share_p").focus();
                        }



                    }else{

                        this.setState({
                            protection: false
                        });

                        if(response.data.type === 1){

                            toast.error('Please enter your BO/Folio in individual tab. You have entered your BO/Folio in wrong tab.');

                        } else if(response.data.type === 2){

                            toast.error('Please enter your BO/Folio in company tab. You have entered your BO/Folio in wrong tab.');
                        }
                    }

                } else {

                    this.setState({
                        protection: false,
                        name: null
                    });

                    if(document.getElementById("PName")){
                        document.getElementById("PName").value = '';
                    }

                    if(document.getElementById("ProxyName")){
                        document.getElementById("ProxyName").value = '';
                    }



                }
            }).catch((error) => {
                if(error.response){
                    console.log(error.response);
                }
            });

            // e.preventDefault();
        }
    }

    handleSubmit = (event) => {

        localStorage.isProxy = "Proxy";
        localStorage.proxy_tp = this.state.proxy_tp;

		localStorage.isMultipleCompany = "";
        localStorage.company_tp = '';

        let userAgent = browserName+' '+fullBrowserVersion+' '+deviceType+' '+engineName+' '+engineVersion+' '+osName+' '+osVersion+' ';

        const key = this.key;
        const url = `${localStorage.apiHost}/shareholder-login`;
        axios({
            method: 'post',
            url: url,
            data: {
                'name': this.state.name,
                'password': this.state.password,
                'bo-id': this.state.bo_id,
                'total-share': this.state.total_share,
                'type': '3',
                'phone': this.state.mobile,
                'otp_for': this.state.otp_for,
                'otp_email': this.state.otp_email,
                'proxy-name': this.state.proxy_name,
                'proxy_boid': this.state.proxy_boid,
                'program-id': localStorage.programId,
                'email': localStorage.enableEmail == 1 ? this.state.email : '',
                'nid-number': localStorage.enableNid == 1 ? this.state.nid_number : '',
                'dob': localStorage.enableDob == 1 ? this.state.dob : '',
                'agm-type': this.props.agmType,
				'ip': localStorage.ip,
                'user-agent': userAgent,
                'proxyusername': this.state.proxyusername,
                'proxypassword': this.state.proxypassword,
                'proxy_tp': this.state.proxy_tp
            },
            headers: {
                'key': key
            }

        }).then(response => {

            if (response.data.status === 200) {

                if(this.state.proxy_tp === 1){
					Notiflix.Report.success(
						'Shareholders',
						response.data.multi_shareholders,
						'Continue',
						function(){

							this.proceedLogin(response);

						}.bind(this, response),
						{
							plainText: false,
                            messageMaxLength: 5000
						}
					);
				}else{
					this.proceedLogin(response);
				}

            } else {

                toast.error(response.data.message);

            }
        }).catch((error) => {
            if(error.response){
                console.log(error.response);
            }
        });

        event.preventDefault();
    }

    proceedLogin = (response) => {
        if (response.data.online_login_status == 0) {

            if (this.props.otp === "false") {

                localStorage.loginID = response.data.id;
                localStorage.token = response.headers.token;
                localStorage.isLogin = "true";
                localStorage.userName = response.data.name;
                localStorage.userType = "Shareholder"
                this.props.onClick("true");
                toast.success(response.data.message);

            } else {
                localStorage.otp_token = response.headers.token;
                localStorage.isLogin = "false";
                localStorage.otpPhone = this.state.mobile;
                this.props.onClick("false");

            }

        }else{

            if(localStorage.only_one_device_login === '1'){
                Notiflix.Report.warning(
                    localStorage.logoutConfirmationHeading,
                    localStorage.only_one_device_login_text,
                    'Close'
                );
            }else{

                Notiflix.Confirm.show(
                    localStorage.logoutConfirmationHeading,
                    localStorage.logoutConfirmationText,
                    localStorage.loginAnywayButton,
                    localStorage.goBackButton,
                    function(){

                        // Login Anyway button callback

                        const key = this.key;
                        const url = `${localStorage.apiHost}/shareholder-logout-other-device`;

                        axios({

                            method: 'post',
                            url: url,
                            headers: {
                                'key': key,
                                'token': response.headers.token,
                            }

                        }).then(response2 => {

                            // console.log(response2.data);

                            if (response2.data.status === 200) {

                                toast.success(response2.data.message);

                                if (this.props.otp === "false") {


                                    localStorage.loginID = response.data.id;
                                    localStorage.token = response2.headers.token;
                                    localStorage.isLogin = "true";
                                    localStorage.userName = response2.data.name;
                                    localStorage.userType = "Shareholder"
                                    this.props.onClick("true");


                                } else {

                                    localStorage.otp_token = response2.headers.token;
                                    localStorage.isLogin = "false";
                                    localStorage.otpPhone = this.state.mobile;
                                    this.props.onClick("false");


                                }

                            }else{

                                toast.error(response2.data.message);

                            }

                        }).catch((error) => {
                            if(error.response){
                                console.log(error.response);
                            }
                        });

                    }.bind(this, response),
                    false
                );
            }
        }
    }

    render() {
        return (
            <div className="tab-pane fade show active" id="nav-proxy" role="tabpanel" aria-labelledby="nav-proxy-tab">
                <div className="proxy-form px-4 py-1">
                    <form name="login" onSubmit={this.handleSubmit}>

                        {localStorage.multiple_proxy === '1' &&
                        <>
                            <label><input type="radio" checked={this.state.proxy_tp === 0} name="proxy_tp" value="0" onChange={(e) => this.radioHandler(0)}></input> Single Proxy</label> &nbsp;

                            <label><input type="radio" checked={this.state.proxy_tp === 1} name="proxy_tp" value="1" onChange={(e) => this.radioHandler(1)}></input> Multiple Proxy</label><br></br>
                        </>
                        }

                        {localStorage.multiple_proxy === '1' && this.state.proxy_tp === 1 &&

                        <div className="multi_proxy_container">
                            <div className="form-group">
                                <label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
                                    Username:
                                </label>
                                <input
                                onChange={e => { this.handleChange(e) }}
                                type="text"
                                name="proxyusername"
                                className="form-control"
                                placeholder={`Enter Username`} required />
                            </div>

                            <div className="form-group">
                                <label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
                                    Password:
                                </label>
                                <input
                                onChange={e => { this.handleChange(e) }}
                                type="password"
                                name="proxypassword"
                                className="form-control"
                                placeholder={`Enter Password`} required />
                            </div>
                        </div>

                        }

                        {this.state.proxy_tp === 0 &&

                        <div className="single_proxy_container">

                            <div className="form-group">
                                <label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
                                    {localStorage.boidInputLabel === "null" ? 'BO ID / Folio ID' : localStorage.boidInputLabel}:
                                </label>
                                <input onChange={e => { this.handleChange(e) }} type="text" name="bo_id" className="form-control" id="bo_id"
                                    placeholder={`Enter Your ${localStorage.boidInputLabel === "null" ? "BO ID / Folio ID:" : localStorage.boidInputLabel}`} required />
                            </div>

                            {(localStorage.bank_asia === '1' || localStorage.proxy_boid_login === '1') &&
                            <div className="form-group">
                                <label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
                                    Proxy BO ID / Folio ID:
                                </label>
                                <input onChange={this.handleChange} type="text" name="proxy_boid" className="form-control" id="ProxyBOID"
                                    placeholder={`Enter Proxy BO ID / Folio ID:`}
                                    required />
                            </div>
                            }

                            {localStorage.login_proxy_sh_name == '1' &&
                            <div className="form-group">
                                <label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
                                    {localStorage.shareholderNameInputLabel === "null" ? 'Shareholder Name' : localStorage.shareholderNameInputLabel}:
                                </label>
                                <input disabled onChange={this.handleChange} type="text" name="name" className="form-control" id="PName"
                                    placeholder="Shareholder's Name"
                                    required />
                            </div>
                            }
                            {localStorage.login_proxy_share == '1' &&
                            <div className="form-group">
                                <label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
                                    {localStorage.totalShareInputLabel === "null" ? 'Total Share' : localStorage.totalShareInputLabel}:
                                </label>
                                <input onChange={this.handleChange} type="number" min="0" name="total_share" className="form-control" id="total_share_p"
                                    placeholder={`Enter Your ${localStorage.totalShareInputLabel === "null" ? "Total Share" : localStorage.totalShareInputLabel}`}
                                    required disabled={localStorage.bank_asia === '1'}/>
                            </div>
                            }

                            {localStorage.login_proxy_name == '1' && localStorage.bank_asia === '0' &&
                            <div className="form-group">
                                <label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
                                    { localStorage.proxyNameInputLabel === "null" ? 'Name of Proxy' :  localStorage.proxyNameInputLabel}:
                                </label>
                                <input onChange={this.handleChange} type="text" name="proxy_name" className="form-control" id="ProxyName"
                                    placeholder={`Enter ${ localStorage.proxyNameInputLabel === "null" ? "Proxy Name" : localStorage.proxyNameInputLabel}`}
                                    required />
                            </div>
                            }

                            {this.state.protection &&
                            <div className="form-group">
                                <label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
                                    Password:
                                </label>
                                <input onChange={this.handleChange} type="password" name="password" className="form-control" placeholder={`Enter Password`}required />
                            </div>
                            }

                            {localStorage.enableEmail == 1 &&
                                <div className="form-group">
                                    <label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
                                        {localStorage.emailInputLabel === "null" ? 'Email' : localStorage.emailInputLabel}:
                                    </label>
                                    <input onChange={this.handleChange} type="email" name="email" className="form-control" id="email" placeholder="Enter Your Email" />
                                </div>
                            }

                            {localStorage.enableNid == 1 &&
                                <div className="form-group">
                                    <label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
                                        {localStorage.nidInputLabel === "null" ? 'NID Number' : localStorage.nidInputLabel}:
                                    </label>
                                    <input onChange={this.handleChange} type="text" name="nid_number" className="form-control" id="nid_number" placeholder="Enter Your NID Number" maxLength="16"/>
                                </div>
                            }

                            {localStorage.enableDob == 1 &&
                                <div className="form-group">
                                    <label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
                                        {localStorage.dateOfBirthInputLabel === "null" ? 'Date of Birth' : localStorage.dateOfBirthInputLabel}:
                                    </label> <br></br>

                                    <DatePicker
                                        className="form-control"
                                        placeholderText="Enter Your Date of Birth"
                                        selected={ this.state.dob }
                                        onChange={ this.handleChange2 }
                                        name="dob"
                                        dateFormat="d MMM, yyyy"
                                        value={this.state.dob}
                                    />
                                </div>
                            }

                        </div>

                        }

                        {this.props.otp === "true" &&
                            <div className="form-group">
                                <label className="font-weight-bold" style={{fontSize:localStorage.loginLabelFontSize, color:localStorage.loginLabelColor}}>
                                    {/* {localStorage.mobileInputLabel === "null" ? 'Mobile Number' : localStorage.mobileInputLabel}: */}
                                    {this.state.otp_for == 'mobile' ? 'Mobile Number':'Email Address'}:
                                    <div className="btn-group" style={{ marginLeft:'10px', marginTop: '8px' }} data-toggle="buttons">

                                                <label className="btn btn-default">
                                                    <input type="radio" onClick={() => {this.setState({otp_for:'mobile'}); localStorage.mobileInputLabel = null; localStorage.otp_for = 'mobile'}} name="otpFor" value="1" checked={this.state.otp_for == 'mobile' ? true : false} /> &nbsp; Mobile &nbsp;
                                                </label>

                                                <label className="btn btn-default active">
                                                    <input type="radio" onClick={() => {this.setState({otp_for:'email'}); localStorage.mobileInputLabel = 'Email Address'; localStorage.otp_for = 'email'}} name="otpFor" checked={this.state.otp_for == 'email' ? true : false} value="0" /> &nbsp; Email
                                                </label>

                                            </div>
                                </label>
                                {/* <input onChange={this.handleChange} type="text" name="mobile" className="form-control" id="mobile"
                                    placeholder={`${localStorage.mobileInputPlaceholder === "null" ? "e.g 017xxxxxxx" : localStorage.mobileInputPlaceholder}`}
                                    minLength="11"
                                    required /> */}
                                    {this.state.otp_for == 'mobile' ?
                                            (<input onChange={this.handleChange} type="number" name="mobile" className="form-control" id="mobile"
                                                placeholder={`${localStorage.mobileInputPlaceholder === "null" ? "e.g 017xxxxxxx" : localStorage.mobileInputPlaceholder}`}
                                                minLength="11"
                                                required />)
                                            : (
                                                <input onChange={this.handleChange} type="email" name="otp_email" className="form-control" id="otp_email" placeholder="Enter Your Email" />
                                            )
                                        }
                            </div>
                        }

                        <button
                            type="submit"
                            className="mt-1 btn btn-login"
                            style={{background:localStorage.loginButtonBgColor, color:localStorage.loginButtonTextColor}}
                        >
                            {localStorage.loginButton === "null" ? 'Login' : localStorage.loginButton}
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

export default ProxyLogin;
