import React from "react";
import axios from 'axios';
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Notiflix from 'notiflix';
import "notiflix/dist/notiflix-3.2.6.min.css";
import { browserName, fullBrowserVersion, engineName, engineVersion, deviceType, osName, osVersion } from 'react-device-detect';


class IndividualLogin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: null,
            bo_id: null,
            total_share: null,
            otp_for: 'mobile',
            otp_email: null,
            mobile: null,
            email: null,
            nid_number: null,
            dob: new Date(),
            autoName: '',
            password: '',
            protection: false,
            typeStatus: 1,
            birthMonth: null,
            birthDay: null,
            birthYear: null
        }
        this.key = 'YUIKjuiKJIolkqUiWFDIE5LALyuiolSDwsDXCvBWzMq2gwWBvJcS8kl7RTYuJHTyuj'
    }


    handleChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        console.log(value);
        console.log(name);
        if (localStorage.bank_asia === '1') {

            this.setState({

                [name]: value

            }, () => {

                this.getName();

            });

        } else {

            if (name === 'bo_id') {

                // console.log(name);

                this.setState({

                    [name]: value

                }, () => {

                    this.getName();

                });

            } else {

                this.setState({

                    [name]: value

                });
            }

        }
    }

    handleChange2 = (value) => {

        this.setState({

            dob: value

        }, () => {

            this.getName();

        });
    }

    handleChangeDay = (event) => {

        this.setState({

            birthDay: event.target.value

        }, () => {

            this.getName();

        });
    }

    handleChangeMonth = (event) => {

        this.setState({

            birthMonth: event.target.value

        }, () => {

            this.getName();

        });
    }

    handleChangeYear = (event) => {

        this.setState({

            birthYear: event.target.value

        }, () => {

            this.getName();

        });
    }

    onRadioChange = (event) => {

        let valueInt = event.target.value * 1;

        // console.log(valueInt);

        // this.setState({
        //     typeStatus: valueInt
        // });

        this.setState({

            typeStatus: valueInt,
            name: null,
            bo_id: null,
            total_share: null,
            birthMonth: null,
            birthDay: null,
            birthYear: null

        }, () => {

            this.getName();

        });

        if (document.getElementById("shName")) {
            document.getElementById("shName").value = '';
        }

        if (document.getElementById("total_share_ind")) {
            document.getElementById("total_share_ind").value = '';
        }

        if (document.getElementById("bo_id")) {
            document.getElementById("bo_id").value = '';
        }

    }

    getName = (e) => {

        // console.log(this.state.bo_id);
        // console.log(this.state.total_share);

        if (localStorage.bank_asia === '1') {

            const key = this.key;
            const url = `${localStorage.apiHost}/get-name-ba`;
            const dofb = this.state.birthDay + ' ' + this.state.birthMonth + ', ' + this.state.birthYear;
            console.log(dofb);

            axios({
                method: 'post',
                url: url,
                data: {
                    'type': 1,
                    'type-status': this.state.typeStatus,
                    'boid': this.state.bo_id,
                    'dob': dofb,
                    'total-share': this.state.total_share,
                    'program-id': localStorage.programId,
                },
                headers: {
                    'key': key
                }

            }).then(response => {

                // console.log(response.data);

                if (response.data.status === 200) {

                    document.getElementById("shName").value = response.data.name;

                    if (document.getElementById("total_share_ind")) {
                        document.getElementById("total_share_ind").value = response.data.total_share;
                    }

                    // document.getElementById("total_share_ind").focus();

                    if (this.state.typeStatus === 1) {
                        this.setState({
                            name: response.data.name,
                            total_share: response.data.total_share,
                        });
                    }

                } else {

                    document.getElementById("shName").value = '';

                    if (document.getElementById("total_share_ind")) {
                        document.getElementById("total_share_ind").value = '';
                    }

                    if (this.state.typeStatus === 1) {
                        this.setState({
                            name: null,
                            total_share: null,
                        });
                    }

                }
            });

        } else {

            const key = this.key;
            const url = `${localStorage.apiHost}/get-name`;

            axios({
                method: 'post',
                url: url,
                data: {
                    'boid': this.state.bo_id,
                    'program-id': localStorage.programId,
                },
                headers: {
                    'key': key
                }

            }).then(response => {

                // console.log(response.data);

                if (response.data.status === 200) {

                    if (response.data.type === 1) {

                        if (response.data.protected === 1) {
                            this.setState({
                                protection: true
                            });
                        }

                        if (document.getElementById("shName")) {
                            document.getElementById("shName").value = response.data.name;
                            this.setState({
                                name: response.data.name
                            });
                        }

                        if (document.getElementById("total_share_ind")) {
                            document.getElementById("total_share_ind").focus();
                        }



                    } else {

                        this.setState({
                            protection: false
                        });

                        if (response.data.type === 2) {

                            toast.error('Please enter your BO/Folio in company tab. You have entered your BO/Folio in wrong tab.');

                        } else if (response.data.type === 3) {

                            toast.error('Please enter your BO/Folio in proxy tab. You have entered your BO/Folio in wrong tab.');

                        }
                    }


                } else {

                    this.setState({
                        protection: false,
                        name: null
                    });

                    if (document.getElementById("shName")) {
                        document.getElementById("shName").value = '';
                    }


                }
            }).catch((error) => {
                if (error.response) {
                    console.log(error.response);
                }
            });

            // e.preventDefault();

        }
    }

    handleSubmit = (event) => {

        localStorage.isProxy = "";
        localStorage.proxy_tp = "";

        localStorage.isMultipleCompany = "";
        localStorage.company_tp = '';

        let userAgent = browserName + ' ' + fullBrowserVersion + ' ' + deviceType + ' ' + engineName + ' ' + engineVersion + ' ' + osName + ' ' + osVersion + ' ';

        const dofb = this.state.birthDay + ' ' + this.state.birthMonth + ', ' + this.state.birthYear;

        const key = this.key;
        const url = `${localStorage.apiHost}/shareholder-login`;
        const data = {
            'name': this.state.name,
            'password': this.state.password,
            'bo-id': this.state.bo_id,
            'total-share': this.state.total_share,
            'type': '1',
            'phone': this.state.mobile,
            'program-id': localStorage.programId,
            'email': localStorage.enableEmail == 1 ? this.state.email : '',
            'otp_for': this.state.otp_for,
            'otp_email': this.state.otp_email,
            'nid-number': localStorage.enableNid == 1 ? this.state.nid_number : '',
            'dob': localStorage.enableDob == 1 ? this.state.dob : '',
            'dobba': dofb,
            'agm-type': this.props.agmType,
            'ip': localStorage.ip,
            'user-agent': userAgent,
            'type-status': this.state.typeStatus,
        };
        // console.log(data);

        axios({
            method: 'post',
            url: url,
            data: data,
            headers: {
                'key': key
            }

        }).then(response => {

            if (response.data.status === 200) {

                // console.log(response.data);
                // console.log(response.headers);

                if (response.data.online_login_status == 0) {

                    if (this.props.otp === "false") {

                        localStorage.loginID = response.data.id;
                        localStorage.token = response.headers.token;
                        localStorage.isLogin = "true";
                        localStorage.userName = response.data.name;
                        localStorage.userType = "Shareholder"
                        this.props.onClick("true");
                        toast.success(response.data.message);

                    } else {

                        localStorage.otp_token = response.headers.token;
                        localStorage.isLogin = "false";
                        localStorage.otpPhone = this.state.mobile;
                        this.props.onClick("false");

                    }

                } else {

                    if (localStorage.only_one_device_login === '1') {
                        Notiflix.Report.warning(
                            localStorage.logoutConfirmationHeading,
                            localStorage.only_one_device_login_text,
                            'Close'
                        );
                    } else {

                        Notiflix.Confirm.show(
                            localStorage.logoutConfirmationHeading,
                            localStorage.logoutConfirmationText,
                            localStorage.loginAnywayButton,
                            localStorage.goBackButton,
                            function () {

                                // console.log(response.headers.token);

                                // Login Anyway button callback

                                const url = `${localStorage.apiHost}/shareholder-logout-other-device`;

                                axios({

                                    method: 'post',
                                    url: url,
                                    headers: {
                                        'key': this.key,
                                        'token': response.headers.token,
                                    }

                                }).then(response2 => {

                                    // console.log(response2.data);

                                    if (response2.data.status === 200) {

                                        toast.success(response2.data.message);

                                        if (this.props.otp === "false") {

                                            localStorage.loginID = response.data.id;
                                            localStorage.token = response2.headers.token;
                                            localStorage.isLogin = "true";
                                            localStorage.userName = response2.data.name;
                                            localStorage.userType = "Shareholder"
                                            this.props.onClick("true");

                                        } else {

                                            localStorage.otp_token = response2.headers.token;
                                            localStorage.isLogin = "false";
                                            localStorage.otpPhone = this.state.mobile;
                                            this.props.onClick("false");

                                        }

                                    } else {

                                        toast.error(response2.data.message);

                                    }

                                }).catch((error) => {
                                    if (error.response) {
                                        console.log(error.response);
                                    }
                                });

                            }.bind(this, response),
                            false
                        );

                    }

                }


            } else {
                toast.error(response.data.message);
            }
        }).catch((error) => {
            if (error.response) {
                console.log(error.response);
            }
        });
        event.preventDefault();
    }


    render() {

        const alldate = [];
        for (let i = 1; i < 32; i++) { alldate.push(i); }

        const allmonth = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        const allyear = [];
        for (let i = 1930; i < 2016; i++) { allyear.push(i); }

        // const [startDate, setStartDate] = useState(new Date());

        return (
            <div className="tab-pane fade show active" id="nav-individual" role="tabpanel" aria-labelledby="nav-individual-tab">
                <div className="individual-form px-4 py-1">
                    <form onSubmit={this.handleSubmit}>

                        {localStorage.bank_asia === '1' ?

                            <>
                                <div className="form-group">
                                    <div className="form-check form-check-inline">
                                        <label>
                                            <input
                                                type="radio"
                                                value="1"
                                                checked={this.state.typeStatus === 1}
                                                // onChange={e => { this.onRadioChange(e); this.getName(e) }}
                                                onChange={this.onRadioChange}
                                                name="typeStatus"
                                                id="inlineRadio1"
                                            /> &nbsp;
                                            BO ID &nbsp;
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label>
                                            <input
                                                type="radio"
                                                value="2"
                                                checked={this.state.typeStatus === 2}
                                                // onChange={e => { this.onRadioChange(e); this.getName(e) }}
                                                onChange={this.onRadioChange}
                                                name="typeStatus"
                                                id="inlineRadio2"
                                            /> &nbsp;
                                            Folio ID
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="font-weight-bold" style={{ fontSize: localStorage.loginLabelFontSize, color: localStorage.loginLabelColor }}>
                                        {this.state.typeStatus === 1 ? 'BO ID' : 'Folio ID'}:
                                    </label>
                                    <input
                                        // onChange={e => { this.handleChange(e); this.getName(e) }}
                                        onChange={this.handleChange}
                                        type="text" name="bo_id" className="form-control" id="bo_id"
                                        placeholder={`Enter Your ${this.state.typeStatus === 1 ? 'BO ID' : 'Folio ID'}`}
                                        required minLength="1" />
                                </div>

                                {this.state.typeStatus === 1 &&
                                    <div className="form-group">

                                        <label className="font-weight-bold" style={{ fontSize: localStorage.loginLabelFontSize, color: localStorage.loginLabelColor }}>
                                            {localStorage.dateOfBirthInputLabel === "null" ? 'Date of Birth' : localStorage.dateOfBirthInputLabel}:
                                        </label>

                                        <br></br>

                                        <select defaultValue={''} onChange={this.handleChangeDay} className="form-control form-control-sm d-inline mr-3 mb-2" style={{ width: '100px' }} required>

                                            <option value='' disabled>Day</option>

                                            {alldate.map(alldate => (
                                                <option key={alldate} value={alldate}>{alldate}</option>
                                            ))}
                                        </select>

                                        <select defaultValue={''} onChange={this.handleChangeMonth} className="form-control form-control-sm d-inline mr-3 mb-2" style={{ width: '100px' }} required>

                                            <option value='' disabled>Month</option>

                                            {allmonth.map(allmonth => (
                                                <option key={allmonth} value={allmonth}>{allmonth}</option>
                                            ))}
                                        </select>

                                        <select defaultValue={''} onChange={this.handleChangeYear} className="form-control form-control-sm d-inline mr-3" style={{ width: '100px' }} required>

                                            <option value='' disabled>Year</option>

                                            {allyear.map(allyear => (
                                                <option key={allyear} value={allyear}>{allyear}</option>
                                            ))}
                                        </select>

                                        {/* <DatePicker
                                    className="form-control"
                                    placeholderText="Enter Your Date of Birth"
                                    selected={ this.state.dob }
                                    // onChange={e => { this.handleChange2(e); this.getName(e) }}
                                    onChange={this.handleChange2}
                                    name="dob"
                                    dateFormat="d MMMM, yyyy"
		                            value={this.state.dob}
                                    required
                                /> */}
                                        {/* <input onChange={this.handleChange} type="text" name="dob" className="form-control" id="dob" placeholder="Enter Your Date Of Birth" required/> */}
                                    </div>
                                }

                                {this.state.typeStatus === 2 &&

                                    <div className="form-group">
                                        <label className="font-weight-bold" style={{ fontSize: localStorage.loginLabelFontSize, color: localStorage.loginLabelColor }}>
                                            {localStorage.totalShareInputLabel === "null" ? 'Total Share' : localStorage.totalShareInputLabel}:
                                        </label>
                                        <input
                                            // onChange={e => { this.handleChange(e); this.getName(e) }}
                                            onChange={this.handleChange}
                                            type="number"
                                            min="0"
                                            name="total_share"
                                            className="form-control"
                                            placeholder={'Total Share'}
                                            required />
                                    </div>

                                }

                                <div className="form-group">
                                    <label className="font-weight-bold" style={{ fontSize: localStorage.loginLabelFontSize, color: localStorage.loginLabelColor }}>
                                        {localStorage.shareholderNameInputLabel === "null" ? 'Shareholder Name' : localStorage.shareholderNameInputLabel}:
                                    </label>
                                    <input onChange={this.handleChange} type="text" name="name" className="form-control" id="shName" placeholder="Shareholder's Name" disabled />
                                </div>

                                {this.state.typeStatus === 1 &&

                                    <div className="form-group">
                                        <label className="font-weight-bold" style={{ fontSize: localStorage.loginLabelFontSize, color: localStorage.loginLabelColor }}>
                                            {localStorage.totalShareInputLabel === "null" ? 'Total Share' : localStorage.totalShareInputLabel}:
                                        </label>
                                        <input
                                            onChange={this.handleChange}
                                            type="number"
                                            min="0"
                                            name="total_share"
                                            className="form-control"
                                            id="total_share_ind"
                                            placeholder={`Total Share`}
                                            disabled
                                        />
                                    </div>

                                }

                            </>
                            :

                            <>

                                <div className="form-group">
                                    <label className="font-weight-bold" style={{ fontSize: localStorage.loginLabelFontSize, color: localStorage.loginLabelColor }}>
                                        {localStorage.boidInputLabel === "null" ? 'BO ID / Folio ID' : localStorage.boidInputLabel}:
                                    </label>
                                    <input onChange={e => { this.handleChange(e) }}
                                        type="text" name="bo_id" className="form-control" id="bo_id"
                                        placeholder={`Enter Your ${localStorage.boidInputLabel === "null" ? "BO ID / Folio ID" : localStorage.boidInputLabel}`}
                                        required minLength="1" />
                                </div>
                                {localStorage.login_individual_name == '1' &&
                                    <div className="form-group">
                                        <label className="font-weight-bold" style={{ fontSize: localStorage.loginLabelFontSize, color: localStorage.loginLabelColor }}>
                                            {localStorage.shareholderNameInputLabel === "null" ? 'Shareholder Name' : localStorage.shareholderNameInputLabel}:
                                        </label>
                                        <input onChange={this.handleChange} disabled type="text" name="name" className="form-control" id="shName" placeholder="Shareholder's Name" required />
                                    </div>
                                }
                                {localStorage.login_individual_share == '1' &&
                                    <div className="form-group">
                                        <label className="font-weight-bold" style={{ fontSize: localStorage.loginLabelFontSize, color: localStorage.loginLabelColor }}>
                                            {localStorage.totalShareInputLabel === "null" ? 'Total Share' : localStorage.totalShareInputLabel}:
                                        </label>
                                        <input onChange={this.handleChange} type="number" min="0" name="total_share" className="form-control" id="total_share_ind"
                                            placeholder={`Enter Your ${localStorage.totalShareInputLabel === "null" ? "Total Share" : localStorage.totalShareInputLabel}`}
                                            required />
                                    </div>
                                }
                                {this.state.protection &&
                                    <div className="form-group">
                                        <label className="font-weight-bold" style={{ fontSize: localStorage.loginLabelFontSize, color: localStorage.loginLabelColor }}>
                                            Password:
                                        </label>
                                        <input onChange={this.handleChange} type="password" name="password" className="form-control" placeholder={`Enter Password`} required />
                                    </div>
                                }
                                {this.props.otp === "true" &&
                                    <div className="form-group">
                                        <label className="font-weight-bold" style={{ fontSize: localStorage.loginLabelFontSize, color: localStorage.loginLabelColor, }}>
                                            {/* {localStorage.mobileInputLabel === "null" ? 'Mobile Number' : localStorage.mobileInputLabel}: */}
                                            {this.state.otp_for == 'mobile' ? 'Mobile Number':'Email Address'}:
                                            {/* <div className="email_mobile">
                                        <input name="otp_for" type="radio" onClick={() => {this.setState({otp_for:'mobile'}); localStorage.mobileInputLabel = null}} />Mobile / <input name="otp_for" type="radio" onClick={() => {this.setState({otp_for:'email'}); localStorage.mobileInputLabel = 'Email Address'}} />Email
                                    </div> */}
                                            <div className="btn-group" style={{ marginLeft:'10px', marginTop: '8px' }} data-toggle="buttons">

                                                <label className="btn btn-default">
                                                    <input type="radio" onClick={() => {this.setState({otp_for:'mobile'}); localStorage.mobileInputLabel = null; localStorage.otp_for = 'mobile'}} name="otpFor" value="1" checked={this.state.otp_for == 'mobile' ? true : false} /> &nbsp; Mobile &nbsp;
                                                </label>

                                                <label className="btn btn-default active">
                                                    <input type="radio" onClick={() => {this.setState({otp_for:'email'}); localStorage.mobileInputLabel = 'Email Address'; ; localStorage.otp_for = 'email'}} name="otpFor" checked={this.state.otp_for == 'email' ? true : false} value="0" /> &nbsp; Email
                                                </label>

                                            </div>
                                        </label>
                                        {this.state.otp_for == 'mobile' ?
                                            (<input onChange={this.handleChange} type="number" name="mobile" className="form-control" id="mobile"
                                                placeholder={`${localStorage.mobileInputPlaceholder === "null" ? "e.g 017xxxxxxx" : localStorage.mobileInputPlaceholder}`}
                                                minLength="11"
                                                required />)
                                            : (
                                                <input onChange={this.handleChange} type="email" name="otp_email" className="form-control" id="otp_email" placeholder="Enter Your Email" />
                                            )
                                        }

                                    </div>
                                }
                                {localStorage.enableEmail == 1 &&
                                    <div className="form-group">
                                        <label for="email" className="font-weight-bold" style={{ fontSize: localStorage.loginLabelFontSize, color: localStorage.loginLabelColor }}>
                                            {localStorage.emailInputLabel === "null" ? 'Email' : localStorage.emailInputLabel}:
                                        </label>
                                        <input onChange={this.handleChange} type="email" name="email" className="form-control" id="email" placeholder="Enter Your Email" />
                                    </div>
                                }

                                {localStorage.enableNid == 1 &&
                                    <div className="form-group">
                                        <label for="nid_number" className="font-weight-bold" style={{ fontSize: localStorage.loginLabelFontSize, color: localStorage.loginLabelColor }}>
                                            {localStorage.nidInputLabel === "null" ? 'NID Number' : localStorage.nidInputLabel}:
                                        </label>
                                        <input onChange={this.handleChange} type="text" name="nid_number" className="form-control" id="nid_number" placeholder="Enter Your NID Number" maxLength="16" />
                                    </div>
                                }

                                {localStorage.enableDob == 1 &&
                                    <div className="form-group">
                                        <label for="dob" className="font-weight-bold" style={{ fontSize: localStorage.loginLabelFontSize, color: localStorage.loginLabelColor }}>
                                            {localStorage.dateOfBirthInputLabel === "null" ? 'Date of Birth' : localStorage.dateOfBirthInputLabel}:
                                        </label> <br></br>

                                        <DatePicker
                                            className="form-control"
                                            placeholderText="Enter Your Date of Birth"
                                            selected={this.state.dob}
                                            onChange={this.handleChange2}
                                            name="dob"
                                            dateFormat="d MMM, yyyy"
                                            value={this.state.dob}
                                        />
                                        {/* <input onChange={this.handleChange} type="text" name="dob" className="form-control" id="dob" placeholder="Enter Your Date Of Birth" required/> */}
                                    </div>
                                }

                            </>

                        }



                        <button type="submit" className="mt-1 btn btn-login" value="submit"
                            style={{ background: localStorage.loginButtonBgColor, color: localStorage.loginButtonTextColor }}>
                            {localStorage.loginButton === "null" ? 'Login' : localStorage.loginButton}
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

export default IndividualLogin;
